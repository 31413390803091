import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "gatsby";

const styles = (theme) => ({
  fullSizeBackground: {
    position: "absolute !important",
    zIndex: -1,
  },

  imageWrapper: {
    width: "150%",

    ".home-content &": {
      height: "100%",
      width: "100%",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      height: "100%",
      width: "100%",
    },
  },

  outerWrapper: {
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    overflow: "hidden",

    ".home-content &": {
      extend: "fullSizeBackground",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      extend: "fullSizeBackground",
      position: "fixed !important",
      zIndex: -1,
    },
  },
});

const Background = ({ classes, fluid, objectFit, objectPosition, onLoad }) => {
  return (
    <div className={classes.outerWrapper}>
      <Img
        fluid={fluid}
        imgStyle={{ objectFit, objectPosition }}
        className={classes.imageWrapper}
        onLoad={onLoad}
      />
    </div>
  );
};

Background.propTypes = {
  fluid: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  objectFit: PropTypes.string.isRequired,
  objectPosition: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

Background.defaultProps = {
  objectPosition: "50% 50%",
  objectFit: "contain",
};

export default withStyles(styles, { withTheme: true })(Background);

// eslint-disable-next-line no-undef
export const query = graphql`
  fragment BackgroundImageFragment on File {
    childImageSharp {
      fluid(quality: 90, maxWidth: 1200) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
