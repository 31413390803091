import React from "react";
import { StaticQuery, graphql } from "gatsby";

// eslint-disable-next-line react/display-name
export default Component => props => (
  <StaticQuery
    query={graphql`
      {
        image: file(name: { eq: "menu-logo" }) {
          childImageSharp {
            fixed(width: 220, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <Component {...props} logo={data.image.childImageSharp.fixed} />}
  />
);
