import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Swipeable } from "react-swipeable";
import { navigate } from "gatsby";
import { Scrollbars } from "react-custom-scrollbars";

import { setLastScreen, setSidebarVisible } from "../../state/store";

const backgroundValue = (background, theme) => {
  return background || theme.base.colors.background;
};

const styles = (theme) => ({
  container: {
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    color: theme.base.colors.text,
    transform: "translate3D(0,0,0)",
    transition: ".5s ease",
    ".sidebar &": {
      transform: "translate3D(220px,0,0)",
    },
  },

  aside: {
    position: "absolute",
    zIndex: -1,
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },

  main: {
    zIndex: 1,
    position: "relative",
    background: (props) => backgroundValue(props.background, theme),
    transform: "translate3D(0,0,0)",
    width: "100%",
    height: "100vh",
    animationDuration: ".8s",
    overflow: "auto",
  },

  overlay: {
    position: "absolute",
    background: "rgba(255,255,255,.6)",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "none",
    transition: ".5s",
    ".sidebar &": {
      display: "block",
    },
  },

  asNext: {
    animationName: "$mainEntryAsNext",
  },

  asPrev: {
    animationName: "$mainEntryAsPrev",
  },

  "@keyframes mainEntryAsNext": {
    "0%": {
      background: "#555555", // "#ddd",
      transform: "translate3D(100%,0,0)",
    },
    "100%": {
      transform: "translate3D(0,0,0)",
    },
  },

  "@keyframes mainEntryAsPrev": {
    "0%": {
      background: "#555555", // "#ddd",
      transform: "translate3D(-100%,0,0)",
    },
    "100%": {
      transform: "translate3D(0,0,0)",
    },
  },

  swipe: {
    width: "100%",
    height: "100%",
  },

  scrollbarsThumbVertical: {
    backgroundColor: theme.base.colors.scrollbar,
    borderRadius: "2px",
    opacity: "0.7",
  },
});

class Container extends React.Component {
  lastScreen = null;
  lastScreenBackground = null;
  screenTransition = "";
  screenIndex = null;

  componentDidMount() {
    if (this.props.lastScreen) {
      this.lastScreen = this.props.lastScreen;
      this.lastScreenBackground = this.props.lastScreenBackground;

      const lastScreenIndex = this.getScreenIndex(this.props.lastScreenPath);
      this.screenIndex = this.getScreenIndex(this.props.location.pathname);
      this.screenTransition = lastScreenIndex <= this.screenIndex ? "asNext" : "asPrev";
    }

    if (this.props.location.pathname !== this.props.lastScreenPath) {
      this.props.setLastScreen({
        obj: this.props.children,
        background: this.props.background,
        path: this.props.location.pathname,
      });
    }
  }

  componentDidUpdate() {
    if (!this.screenIndex && this.props.screenSequence) {
      this.screenIndex = this.getScreenIndex(this.props.location.pathname);
    }
  }

  getScreenIndex(slug) {
    return this.props.screenSequence.findIndex((el) => el.slug === slug);
  }

  getToScreen(direction) {
    const maxIndex = this.props.screenSequence.length - 1;
    let index = this.screenIndex;

    if (direction === "next") {
      index = index < maxIndex ? index + 1 : 0;
    } else if (direction === "prev") {
      index = index > 0 ? index - 1 : maxIndex;
    }

    return this.props.screenSequence[index].slug;
  }

  onSwipedLeft() {
    const toScreen = this.getToScreen("next");
    navigate(toScreen);
  }

  onSwipedRight() {
    const toScreen = this.getToScreen("prev");
    navigate(toScreen);
  }

  onSwiping = ({ dir }) => {
    if (dir === "Left") {
      this.onSwipedLeft();
    }

    if (dir === "Right") {
      this.onSwipedRight();
    }
  };

  overlayOnClick = (e) => {
    if (this.props.sidebarVisible) {
      this.props.setSidebarVisible(false);
    }
  };

  render() {
    const { children, classes, theme } = this.props;

    const ScrollbarsThumbVertical = (props) => (
      <div {...props} className={classes.scrollbarsThumbVertical} />
    );

    return (
      <div className={classes.container}>
        <main className={`${classes.main} ${classes[this.screenTransition] || ""}`}>
          <Swipeable onSwiping={this.onSwiping} className={classes.swipe}>
            <Scrollbars
              autoHide
              universal
              autoHideTimeout={5000}
              autoHideDuration={1000}
              renderThumbVertical={ScrollbarsThumbVertical}
            >
              {children}
            </Scrollbars>
          </Swipeable>
          <div className={classes.overlay} onClick={this.overlayOnClick} />
        </main>
        <aside
          className={classes.aside}
          style={{ background: backgroundValue(this.lastScreenBackground, theme) }}
        >
          {this.lastScreen}
        </aside>
      </div>
    );
  }
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  screenSequence: PropTypes.array.isRequired,
  lastScreen: PropTypes.node,
  lastScreenPath: PropTypes.string.isRequired,
  lastScreenBackground: PropTypes.string,
  setLastScreen: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.bool.isRequired,
  setSidebarVisible: PropTypes.func.isRequired,
  background: PropTypes.string,
};

const mapStateToProps = (state) => ({
  screenSequence: state.screenSequence,
  lastScreen: state.lastScreen,
  lastScreenBackground: state.lastScreenBackground,
  lastScreenPath: state.lastScreenPath,
  sidebarVisible: state.sidebarVisible,
});

const mapDispatchToProps = {
  setLastScreen,
  setSidebarVisible,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Container));
