// const colors = require("../../src/styles/colors");

module.exports = {
  // homeTitle: "",  // Is this used anywhere?
  siteTitle: "Iszek Baraque",
  shortSiteTitle: "Iszek Baraque (2017) eponymous album.",
  siteDescription: `"Iszek Baraque" (2017) is an eponymous studio album of a singer, guitarist and songwriter Iszek Baraque.`,
  siteUrl: "http://iszekbaraque.com",
  pathPrefix: "/tmp",
  siteImage: "",
  siteLanguage: "en",
  // author
  authorName: "Ivo Silva",
  authorTwitterAccount: "knokit",
  // info
  infoTitle: "Iszek Baraque",
  infoTitleNote: "",
  // // manifest.json
  // manifestName: "MiniCatalog - a blog starter for GatsbyJS",
  // manifestShortName: "PersonalBlog", // max 12 characters
  // manifestStartUrl: "/",
  // manifestBackgroundColor: colors.bg,
  // manifestThemeColor: colors.bg,
  // manifestDisplay: "standalone"

  socialWebsites: {
    facebook: "https://www.facebook.com/Iszek.Baraque.Official/",
    instagram: "https://www.instagram.com/iszekbaraque/"
  }
};
