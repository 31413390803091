import React from "react";
import PropTypes from "prop-types";
import { navigate, withPrefix } from "gatsby";
require("typeface-open-sans");
var FontFaceObserver = require("fontfaceobserver");

import globals from "../../styles/globals";

import { timeoutThrottlerHandler } from "../../utils/helpers";

import Seo from "../Seo";
import Sidebar from "../Sidebar";
import NextButton from "../NextButton";
import SidebarButton from "../Sidebar/SidebarButton";
import { withStyles } from "@material-ui/core/styles";
// import { withMobileDialog } from "material-ui/Dialog";
// import { isWidthDown } from "material-ui/utils/withWidth";

class Layout extends React.Component {
  constructor() {
    super();

    if (typeof window !== `undefined`) {
      this.loadFont("font600", "Open Sans", 600);
      this.loadFont("font300", "Open Sans", 300);
      this.loadFont("font400", "Open Sans", 400);
    }
  }

  state = {
    nextButtonTopOffset: "",
    nextButtonLeftOffset: "",
    toForNextButton: ""
  };

  timeouts = {};

  componentDidMount() {
    const screenSequence = this.buildScreenSequence(this.props.data);
    this.props.setScreenSequence(screenSequence);

    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.resizeThrottler, false);
    }

    this.timeouts.positionNextButton = setTimeout(this.positionNextButton, 600);
  }

  componentWillUnmount() {
    for (const key in this.timeouts) {
      clearTimeout(this.timeouts[key]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.screenSequence !== prevProps.screenSequence) {
      this.setToForNextButton();
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname === withPrefix("/")) {
        this.positionNextButton();
      } else {
        this.setState({ nextButtonTopOffset: "", nextButtonLeftOffset: "" });
      }

      this.setToForNextButton();
    }
  }

  loadFont = (name, family, weight) => {
    const fontClassName = `${name}-loaded`;

    if (window.document.documentElement.className.indexOf(fontClassName) > -1) {
      return;
    }

    const font = new FontFaceObserver(family, { weight });

    font.load().then(
      function() {
        console.debug(`${name} is available`);
        window.document.documentElement.classList.add(fontClassName);
      },
      function() {
        console.debug(`${name} is not available`);
      }
    );
  };

  buildScreenSequence(data) {
    const pages = ((data.pages || {}).edges || []).map(edge => ({
      slug: edge.node.fields.slug,
      slugWithPrefix: withPrefix(edge.node.fields.slug),
      title: edge.node.frontmatter.title
    }));

    const items = ((data.items || {}).edges || []).map(edge => ({
      slug: edge.node.fields.slug,
      slugWithPrefix: withPrefix(edge.node.fields.slug),
      title: edge.node.frontmatter.title
    }));

    const home = { slug: "/", slugWithPrefix: withPrefix("/"), title: "Home" };

    return [home, ...items, ...pages];
  }

  resizeThrottler = () => {
    return timeoutThrottlerHandler(this.timeouts, "resize", 500, this.resizeHandler);
  };

  resizeHandler = () => {
    this.positionNextButton();
  };

  positionNextButton = () => {
    if (location.pathname === withPrefix("/")) {
      const buttonDock = document.querySelector("#nextButtonDock");

      this.setState({
        ...(buttonDock.offsetTop && { nextButtonTopOffset: `${buttonDock.offsetTop}px` }),
        ...(buttonDock.offsetLeft && { nextButtonLeftOffset: `${buttonDock.offsetLeft}px` })
      });
    }
  };

  setToForNextButton() {
    const currentScreen = this.props.location.pathname;
    const sequence = this.props.screenSequence;

    const currentScreenIndex = sequence.findIndex(el => el.slugWithPrefix === currentScreen);
    const toIndex = currentScreenIndex + 1;

    const to = toIndex > sequence.length - 1 ? "/" : sequence[toIndex].slug;

    this.setState({ toForNextButton: to });

    // because we dynamicaly changing a 'to' parameter of the NextButton's gatsby-link component
    // we have to manualy do the job which in usual circumstances gatsby-link does for us automaticaly,
    // prefetch assets for the next path

    // eslint-disable-next-line no-undef
    ___loader.enqueue(to);
  }

  sidebarLinkOnClick = e => {
    e.preventDefault();
    const to = e.currentTarget.getAttribute("href");

    this.props.setSidebarVisible(false);
    this.timeouts.sidebarLinkOnClick = setTimeout(() => {
      navigate(to);
    }, 500);
  };

  sidebarButtonOnClick = e => {
    this.props.setSidebarVisible(this.props.sidebarVisible ? false : true);
  };

  render() {
    const { location, screenSequence, sidebarVisible, data } = this.props;
    const { title, socialWebsites } = data.site.siteMetadata;
    const isHomepage = location.pathname === withPrefix("/");

    return (
      <div className={`layout ${isHomepage ? "home" : ""} ${sidebarVisible ? "sidebar" : ""}`}>
        <Seo />

        {this.props.children}

        <Sidebar
          screenSequence={screenSequence}
          onClick={this.sidebarLinkOnClick}
          headerTitle={title}
          socialWebsites={socialWebsites}
        />
        <NextButton
          topOffset={this.state.nextButtonTopOffset}
          leftOffset={this.state.nextButtonLeftOffset}
          to={this.state.toForNextButton}
        />
        <SidebarButton onClick={this.sidebarButtonOnClick} sidebarVisible={sidebarVisible} />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  screenSequence: PropTypes.array.isRequired,
  setScreenSequence: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.bool.isRequired,
  setSidebarVisible: PropTypes.func.isRequired
};

export default withStyles(globals)(Layout);
