import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby";
import Img from "gatsby-image";
import Link from "../Link";

import SocialButton from "../SocialButton";

const styles = (theme) => ({
  sidebar: {
    transform: "translate3D(-220px,0,0)",
    width: "220px",
    height: "100vh",
    background: "#000", // "#333",
    position: "absolute",
    top: 0,
    left: 0,
    color: "#ddd",
    padding: "1.5rem 0",
    transition: "transform .5s ease",

    ".sidebar &": {
      transform: "translate3D(0,0,0)",
    },
  },

  nav: {
    display: "flex",
    flexDirection: "column",
    padding: "0 .8rem",

    "& a": {
      color: "#ddd",
      padding: ".7em .8rem",
      fontSize: "1.2rem",
      letterSpacing: "-.04rem",
    },

    "& a:hover": {
      color: "white",
      background: "#444",
    },
  },

  logo: {
    width: "inherit",

    "& > img": {
      width: "inherit",
      padding: ".8rem",
    },
  },

  socialButtons: {
    extend: theme.mainButtonsTopPosition,
    padding: "0 1.6rem",
  },
});

const Sidebar = (props) => {
  const { classes, headerTitle, socialWebsites, logo } = props;
  const screenSequence = props.screenSequence || [];
  const home = screenSequence.find((el) => el.slug === withPrefix("/"));

  return (
    <aside className={classes.sidebar}>
      <Link to={home ? home.slug : ""} onClick={props.onClick} className={classes.logo}>
        <Img fixed={logo} alt={headerTitle} />
      </Link>

      <nav className={classes.nav}>
        {screenSequence
          .filter((el) => el.slug !== home.slug)
          .map((el) => (
            <Link key={el.slug} to={el.slug} onClick={props.onClick}>
              {el.title}
            </Link>
          ))}
      </nav>

      <div className={classes.socialButtons}>
        {Object.keys(socialWebsites).map((key) => (
          <SocialButton key={key} url={socialWebsites[key]} siteId={key} />
        ))}
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  screenSequence: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  socialWebsites: PropTypes.object,
  logo: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(Sidebar);
