import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Background from "../Background";

const styles = (theme) => ({
  home: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    justifyContent: "center",
    maxWidth: `${theme.breakpoints.values.md}px`,
    margin: "0 auto",
    position: "relative",

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      fontSize: "1.3em",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      fontSize: "1.6em",
    },

    "@media all and (-ms-high-contrast:none)": {
      height: "100%",
    },
  },

  nextButtonDock: {
    extend: theme.mainButtonsTopPosition,

    margin: 0,
    width: "56px",
    height: "56px",
    left: "calc(100vw - 86px)",

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      left: "calc(100vw - 96px)",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      left: "calc(100vw - 106px)",
    },
  },
});

const Home = ({ classes, fluid }) => (
  <div className={`${classes.home} home-content`}>
    <Background fluid={fluid} />
    <div className={classes.nextButtonDock} id="nextButtonDock" />
  </div>
);

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Home);
