import { connect } from "react-redux";
import { setScreenSequence, setSidebarVisible } from "../../state/store";

import Layout from "./layout";

const mapStateToProps = state => ({
  sidebarVisible: state.sidebarVisible,
  screenSequence: state.screenSequence
});

const mapDispatchToProps = {
  setScreenSequence,
  setSidebarVisible
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
