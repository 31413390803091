import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Link from "../Link";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const styles = (theme) => ({
  nextButton: {
    extend: theme.mainButtonsTopPosition,

    position: "absolute",
    right: "58px",
    margin: "28px -28px 0 0",
    transition: ".5s ease",
    animationName: "$buttonEntry",

    ".home &": {
      left: "100%",
    },

    ".sidebar &": {
      transform: "translate3D(220px,0,0)",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      right: "68px",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      right: "78px",
    },

    "@media all and (-ms-high-contrast:none)": {
      transition: 0,
    },
  },

  "@keyframes buttonEntry": {
    "0%": {
      transform: "scale(0)",
    },
    "90%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
});

const NextButton = (props) => {
  const { classes, topOffset, leftOffset, to } = props;

  return (
    <div
      className={classes.nextButton}
      style={{ top: `${topOffset}`, left: `${leftOffset}` }}
      id="nextButton"
    >
      <Fab component={Link} to={to} aria-label="next" className={classes.button} title="Next">
        <ArrowForwardIcon />
      </Fab>
    </div>
  );
};

NextButton.propTypes = {
  classes: PropTypes.object.isRequired,
  topOffset: PropTypes.string.isRequired,
  leftOffset: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(NextButton);
