import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  sidebarButton: {
    extend: theme.mainButtonsTopPosition,

    position: "absolute",
    left: "58px",
    margin: "28px 0 0 -28px",
    transition: "transform .5s ease",

    ".home &": {
      display: "none",
    },

    ".sidebar &": {
      transform: "translate3D(162px,0,0)",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      left: "68px",

      ".sidebar &": {
        transform: "translate3D(152px,0,0)",
      },
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      left: "78px",

      ".sidebar &": {
        transform: "translate3D(142px,0,0)",
      },
    },
  },
});

const SidebarButton = (props) => {
  const { classes, sidebarVisible } = props;

  return (
    <div className={classes.sidebarButton}>
      <Fab aria-label="menu" className={classes.button} title="Menu" onClick={props.onClick}>
        {sidebarVisible ? <CloseIcon /> : <MenuIcon />}
      </Fab>
    </div>
  );
};

SidebarButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(SidebarButton);
