import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { capitalizeFirstLetter } from "../utils/helpers";

const styles = () => ({
  button: {
    fontSize: "22px",

    "& + &": {
      marginLeft: "1.2em",
    },
  },
});

const icons = {
  facebook: faFacebookF,
  instagram: faInstagram,
};

const SocialButton = (props) => {
  const { classes, url, siteId } = props;
  const title = props.title || capitalizeFirstLetter(siteId);
  const icon = props.icon || icons[siteId];

  return (
    <Fab
      aria-label={title}
      className={`${classes.button} social-button`}
      title={title}
      href={url}
      target="_blank"
      rel="noopener"
    >
      <FontAwesomeIcon icon={icon} size="lg" />
    </Fab>
  );
};

SocialButton.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
};

export default withStyles(styles)(SocialButton);
