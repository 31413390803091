import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Container from "../components/Container/";
import Home from "../components/Home/";

const Index = ({ location, data }) => (
  <Layout location={location}>
    <Container location={location}>
      <Home fluid={data.bgImage.childImageSharp.fluid} />
    </Container>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgImage: file(relativePath: { eq: "homepage.jpg" }) {
      ...BackgroundImageFragment
    }
  }
`;
