import React from "react";
import { StaticQuery, graphql } from "gatsby";

// eslint-disable-next-line react/display-name
export default Component => props => (
  <StaticQuery
    query={graphql`{
      site {
        siteMetadata {
          title
          socialWebsites {
            facebook
            instagram
          }
        }
      }

      pages: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
        sort: { fields: [fields___prefix], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
              prefix
            }
            frontmatter {
              title
            }
          }
        }
      }

      items: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//items//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
        sort: { fields: [fields___prefix], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
              prefix
            }
            frontmatter {
              title
            }
          }
        }
      }
    }`}
    render={data => <Component {...props} data={data} />}
  />
);
