import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

// eslint-disable-next-line react/display-name
const Link = React.forwardRef(
  ({ children, to, activeClassName, partiallyActive, ...other }, ref) => {
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const isInternal = /^\/(?!\/)/.test(to);

    // Use Gatsby Link for internal links, and <a> for others
    if (isInternal) {
      return (
        <GatsbyLink
          ref={ref}
          to={to}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
        >
          {children}
        </GatsbyLink>
      );
    }

    return (
      <a href={to} ref={ref} {...other}>
        {children}
      </a>
    );
  }
);

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
};

export default Link;
